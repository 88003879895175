const config = {
  ENVIRONMENT_NAME: 'prod',
  forwoodId: {
    URL: 'https://id.rossing.forwoodsafety.com',
    USER_TOKEN_URL: 'https://5hv8zdudwj.execute-api.us-east-1.amazonaws.com/prod'
  },
  apiGateway: {
    REGION: 'us-east-1',
    URL: 'https://ahxkkrv9nj.execute-api.us-east-1.amazonaws.com/prod'
  },
  reactApp: {
    VERSION: 'admin-container-1.10.2',
    HOSTNAME: 'https://admin.rossing.forwoodsafety.com',
    COMPANY_MICROFRONTEND_URL: 'https://company.rossing.forwoodsafety.com',
    PHYSICAL_LOCATION_MICROFRONTEND_URL: 'https://locations.rossing.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.rossing.forwoodsafety.com',
    WEBSOCKET: 'wss://407rcjdf6d.execute-api.us-east-1.amazonaws.com/prod'
  },
  get forwoodIdAuthUrl() {
    return `${this.forwoodId.URL}/authorize?response_type=token&scope=openid&client_id=${this.forwoodId.APP_CLIENT_ID}&redirect_uri=${this.reactApp.HOSTNAME}&state=`;
  }
};

export default config;
